import { all, takeEvery, put } from 'redux-saga/effects';
import actions from './actions';
/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/Api
 */

function* loadFromApi(response) {
  try {
    yield put(actions.loadFromApiSuccess(response?.payload?.data));
  } catch (error) {
    yield put(actions.loadFromApiError(error));
  }
}

function* loadPermissionsFromApi(response) {
  try {
    yield put(actions.loadPermissionsFromApiSuccess(response?.payload?.data));
  } catch (error) {
    yield put(actions.loadFromApiError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_FROM_API, loadFromApi), takeEvery(actions.LOAD_PERMISSIONS_FROM_API, loadPermissionsFromApi)]);
}
