import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import profile from '@iso/redux/profile/reducer';
import NormalUser from '@iso/redux/normalUser/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ProjectCategory from '@iso/redux/projectCategory/reducer';
import ProductionHouseAdmin from '@iso/redux/productionHouseAdmin/reducer';
import TransactionHistory from '@iso/redux/transactionHistory/reducer';
import SubscriptionPlanManagement from '@iso/redux/subscriptionPlanManagement/reducer';
import Reports from '@iso/redux/reports/reducer';
import Inquiry from '@iso/redux/inquiry/reducer';
import DocumentCategory from '@iso/redux/documentCategory/reducer';
import RoleManagement from '@iso/redux/roleManagement/reducer';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  profile,
  NormalUser,
  LanguageSwitcher,
  ProjectCategory,
  ProductionHouseAdmin,
  TransactionHistory,
  SubscriptionPlanManagement,
  Reports,
  Inquiry,
  DocumentCategory,
  RoleManagement
});
